import React from 'react';
import { IonSkeletonText } from '@ionic/react';

import './styles.scss';

const SkeletonText = props => {
	// Variables and Functions

	// Render
	return (
		<div className={`SkeletonText${props.className ? ` ${props.className}` : ''}`} style={{ ...props.style }}>
			<IonSkeletonText animated style={{ lineHeight: `${props.lineHeight}px` }} />
		</div>
	)
}

SkeletonText.propTypes = {};

export default SkeletonText;