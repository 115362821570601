import React from 'react';
import './styles.scss';


const ContentPanel = props => {
	// Variables and Functions

	// Render
	return (
		<div className={`ContentPanel${props.className ? ` ${props.className}` : ''}${props.small ? ' small' : ''}`} style={{ ...props.style }}>
			{props.children}
		</div>
	)
}

ContentPanel.propTypes = {};

export default ContentPanel;