import "firebase/firestore";
import { A } from 'hookrouter';
import React, { useEffect, useState } from 'react';
import utils from 'synalgic-components/scripts/utils';
import { Header, SkeletonText, Tile, TileContainer } from '../../components';
import { useProfile } from "../../hooks/useProfile";
import './styles.scss';

import * as firebase from "firebase/app";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";

const Home = props => {

    const { userInfo, profileInfo } = useProfile()

    const [screenWidth] = utils.useWindowSize();
    const [courses, coursesLoading] = useCollectionDataOnce(firebase.firestore().collection("fl_content").where('_fl_meta_.schema', '==', 'course'), { idField: "id" });
    const [playableCourses, setPlayableCourses] = useState([]);

    const loading = coursesLoading;

    useEffect(() => {
        if (courses) {
            let pc = [];
            for (let i = 0; i < courses.length; i++) {
                let c = courses[i];
                if (c.enabled) {
                    pc.push(c);
                }
                if (c.length == 20) {
                    break;
                }
            }
            setPlayableCourses(pc);
        }
    }, [loading]);

    const getNumberOfColumns = _ => {
        const containerWidth = Math.min(screenWidth, 1400);
        if (containerWidth > 1200) {
            return 3
        }
        if (containerWidth > 800) {
            return 2
        }
        return 1
    }

    return (
        <div className="Home">
            <Header userInfo={userInfo} profileInfo={profileInfo} courses={courses} >Play Tee to Green</Header>
            <TileContainer numOfColumns={getNumberOfColumns()}>
                {loading ?
                    [0, 0, 0, 0].map((skel, i) => {
                        return (
                            <SkeletonText key={i} lineHeight={300} style={{ borderRadius: 32 }} />
                        )
                    })
                    :
                    playableCourses.map(playableCourse => {
                        return (
                            <A href={`/courses/${playableCourse.id}`} key={playableCourse.id}>
                                {/* <Link to="/courses" key={course.id}> */}
                                <Tile course={playableCourse} />
                                {/* </Link> */}
                            </A>
                        )
                    })
                }
            </TileContainer>
        </div>
    )
}

export default Home;