import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import check from '../../assets/checkmark.svg';

const PricingOption = props => {
    const index = props.index;
    const info = {
        credit: props.CreditAmount,
        value: props.DollarAmount
    }
    // returning -1 value
    // useEffect(() => {
    //     console.log(props.selectedIndex);
    // }, [index]);
    // console.log(index);

    return (
        <div className={styles.PricingOption} onClick={() => {props.onSelect(index);props.cardSelectInfo(info)}}>
            <div className={styles.Numbers}>
                <div className={styles.CreditAmount}>
                    <h2>{props.CreditAmount}</h2>
                    <p>{props.CreditAmount > 1 ? 'credits' : 'credit'}</p>
                </div>
            </div>
            <div className={styles.Price}>
                <h2>${props.DollarAmount}</h2>
            </div>
            <div className={`${styles.Radio} ${props.selectedIndex === index ? `${styles.selected}` : ''}`}>
                <img src={check} alt="checkmark"></img>
            </div>
        </div>
    );
};

PricingOption.propTypes = {
    CreditAmount: PropTypes.string.isRequired,
    DollarAmount: PropTypes.string.isRequired,
};

export default PricingOption;