import React from 'react';
import './App.scss';
import { Home, CourseDetails, Play } from './pages';
import { useRoutes } from 'hookrouter';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faCheckSquare, faCoffee)

const routes = {
  "/": () => <Home />,
  "/courses/": () => <Home />,
  "/courses/:id": ({ id }) => <CourseDetails courseID={id} />,
  "/courses/:id/play/:keyid": ({ id, keyid }) => <Play courseID={id} keyID={keyid}  />
}

function App() {
  const routeResult = useRoutes(routes);
  // TODO: Implement 'Not Found' Page as fallback
  return (
    routeResult // || <NotFoundPage />
  )
}

export default App;
