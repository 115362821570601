import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const PopupBase = props => {
	// Variables and Functions

	// useEffect(() => {
	// 	document.getElementById('popup-backdrop').style() props.popupWidth
	// })

	const getBackdropStyles = _ => {
		let styles = {}
		if (props.popupWidth !== null) {
			styles = { ...styles, gridTemplateColumns: `1fr ${props.popupWidth}px 1fr` }
		}
		return styles
	}

	useMemo(() => getBackdropStyles, [props.popupWidth])

	// Render
	return (
		<>
			<div className="popup-backdrop" style={{ ...getBackdropStyles() }}>
				{[0, 0, 0, 0, 0, 0, 0, 0].map((item, i) => {
					return (
						<div className="popup-spacer" key={i}></div>
					)
				})}
				{props.children}
			</div>
		</>
		// <div className="PopupBase" style={{ ...props.style }}>
		// </div>
	)
}

PopupBase.propTypes = {};

export default PopupBase;