import * as firebase from 'firebase/app';
import Stripe from 'stripe';
const STRIPE_PUBLISHABLE_KEY = 'pk_live_51HtyxhKUAt2VxbSOlCCQh8C4g6S4Yc5mZ0aBuUc0Noz5nlpJTt7Q5WxmZqoKQtqs9PPZeiiOUxovm66EaJwctoIw00zcxPKvx5';
let currentUser = {};
export let customerData = {};
export const initialize = () => {
  firebase.auth().onAuthStateChanged((firebaseUser) => {
    if (firebaseUser) {
      currentUser = firebaseUser;
      firebase
        .firestore()
        .collection('stripe_customers')
        .doc(currentUser.uid)
        .get()
        .then(doc => {
          let prof = doc.data()
          prof.id = doc.id
          // console.log(prof)
          customerData = prof
          startDataListeners();
          return prof
        })
        .catch(err => {
          console.log("Error getting user profile: ", err);
        })
    } else {
      document.getElementById('content').style.display = 'none';
      console.log('user not found, sign in')
    }
  });

}
/**
 * Set up Stripe Elements
 */
const stripe = Stripe(STRIPE_PUBLISHABLE_KEY);
//   const elements = stripe.elements();
//   const cardElement = elements.create('card');
//   cardElement.mount('#card-element');
//   cardElement.on('change', ({ error }) => {
//     const displayError = document.getElementById('error-message');
//     if (error) {
//       displayError.textContent = error.message;
//     } else {
//       displayError.textContent = '';
//     }
//   });

/**
 * Set up Firestore data listeners
*/
function startDataListeners() {
  /**
   * Get all payment methods for the logged in customer
    */

  /**
   * Get all payments for the logged in customer
   */
  firebase
    .firestore()
    .collection('stripe_customers')
    .doc(currentUser.uid)
    .collection('payments')
    .onSnapshot((snapshot) => {
      snapshot.forEach((doc) => {
        const payment = doc.data();

        //  let liElement = document.getElementById(`payment-${doc.id}`);
        //  if (!liElement) {
        //    liElement = document.createElement('li');
        //    liElement.id = `payment-${doc.id}`;
        //  }

        let content = '';
        if (
          payment.status === 'new' ||
          payment.status === 'requires_confirmation'
        ) {
          content = `Creating Payment for ${formatAmount(
            payment.amount,
            payment.currency
          )}`;
        } else if (payment.status === 'succeeded') {
          const card = payment.charges.data[0].payment_method_details.card;
          content = `✅ Payment for ${formatAmount(
            payment.amount,
            payment.currency
          )} on ${card.brand} card •••• ${card.last4}.`;
          //this is another spot where we can do 
          // console.log(content)

          //watch for loader spinner,updates value 
        } else if (payment.status === 'requires_action') {
          content = `🚨 Payment for ${formatAmount(
            payment.amount,
            payment.currency
          )} ${payment.status}`;
          handleCardAction(payment, doc.id);
        } else {
          content = `⚠️ Payment for ${formatAmount(
            payment.amount,
            payment.currency
          )} ${payment.status}`;
        }
      });
    });
}

/**
 * Event listeners
 */

// Signout button
// document
//   .getElementById('signout')
//   .addEventListener('click', () => firebase.auth().signOut());

// Add new card form
// document
//   .querySelector('#payment-method-form')
//   .addEventListener('submit', async (event) => {
//     event.preventDefault();
//     if (!event.target.reportValidity()) {
//       return;
//     }
//     document
//       .querySelectorAll('button')
//       .forEach((button) => (button.disabled = true));

//     const form = new FormData(event.target);
//     const cardholderName = form.get('name');

//     const { setupIntent, error } = await stripe.confirmCardSetup(
//       customerData.setup_secret,
//       {
//         payment_method: {
//           card: cardElement,
//           billing_details: {
//             name: cardholderName,
//           },
//         },
//       }
//     );

//     if (error) {
//       document.querySelector('#error-message').textContent = error.message;
//       document
//         .querySelectorAll('button')
//         .forEach((button) => (button.disabled = false));
//       return;
//     }

//     await firebase
//       .firestore()
//       .collection('stripe_customers')
//       .doc(currentUser.uid)
//       .collection('payment_methods')
//       .add({ id: setupIntent.payment_method });

//     document.querySelector('#add-new-card').open = false;
//     document
//       .querySelectorAll('button')
//       .forEach((button) => (button.disabled = false));
//   });

// Create payment form


//     const data = {
//       payment_method: form.get('payment-method'),
//       currency,
//       amount: formatAmountForStripe(amount, currency),
//       status: 'new',
//     };

//     await firebase
//       .firestore()
//       .collection('stripe_customers')
//       .doc(currentUser.uid)
//       .collection('payments')
//       .add(data);


// /**
//  * Helper functions
//  */

// Format amount for diplay in the UI
export function formatAmount(amount, currency) {
  amount = zeroDecimalCurrency(amount, currency)
    ? amount
    : (amount / 100).toFixed(2);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(amount);
}

// Format amount for Stripe
export function formatAmountForStripe(amount, currency) {
  return zeroDecimalCurrency(amount, currency)
    ? amount
    : Math.round(amount * 100);
}

// Check if we have a zero decimal currency
// https://stripe.com/docs/currencies#zero-decimal
function zeroDecimalCurrency(amount, currency) {
  let numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false;
    }
  }
  return zeroDecimalCurrency;
}

// Handle card actions like 3D Secure
export async function handleCardAction(payment, docId) {
  const { error, paymentIntent } = await stripe.handleCardAction(
    paymentIntent.client_secret
  );
  if (error) {
    alert(error.message);
    payment = error.payment_intent;
  } else if (paymentIntent) {
    payment = paymentIntent;
  }

  await firebase
    .firestore()
    .collection('stripe_customers')
    .doc(currentUser.uid)
    .collection('payments')
    .doc(docId)
    .set(payment, { merge: true });
}