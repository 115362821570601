import { useState, useEffect } from 'react';

const utils = {

    Positions: {
        Top: "Top",
        Left: "Left",
        Right: "Right",
        Bottom: "Bottom"
    },

    useWindowSize: _ => {
        const isClient = typeof window === 'object';

        const getSize = _ => {
            return [
                isClient ? window.innerWidth : undefined,
                isClient ? window.innerHeight : undefined
            ];
        }

        const [windowSize, setWindowSize] = useState(getSize);

        useEffect(_ => {
            if (!isClient) {
                return false;
            }

            const handleResize = _ => {
                setWindowSize(getSize());
            }

            window.addEventListener('resize', handleResize);
            return _ => window.removeEventListener('resize', handleResize);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return windowSize;
    },

    useWindowScroll: _ => {
        const isClient = typeof window === 'object';

        const getScroll = _ => {
            return [
                isClient ? window.scrollX : undefined,
                isClient ? window.scrollY : undefined
            ];
        }

        const [windowScroll, setWindowScroll] = useState(getScroll);

        useEffect(_ => {
            if (!isClient) {
                return false;
            }

            const handleScroll = _ => {
                setWindowScroll(getScroll());
            }

            window.addEventListener('scroll', handleScroll);
            return _ => window.removeEventListener('scroll', handleScroll);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return windowScroll;
    },

    asyncForEach: async (array, callback) => {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

}

export default utils;