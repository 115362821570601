import { faTh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { A } from 'hookrouter';
import React from 'react';
import { Button, SkeletonText } from '../../components';
import utils from '../../scripts/Utilities';
import './styles.scss';


const Banner = props => {
	// Variables and Functions

	// Render
	return (
		<div className="Banner" style={{ ...props.style }}>
			{props.loading ?
				<SkeletonText className="hero-skeleton" />
				:
				<img className="background-image" src={props.featured} alt="Golf Course Background" />
			}
			<div className="background-overlay"></div>
			<div className="inner">
				{props.loading ?
					<SkeletonText className="logo-skeleton" style={{ display: 'none' }} />
					:
					<img className="course-logo" src={props.logo} alt="Golf Course Logo" />
				}
				<A href="/" className="back-button-link">
					<Button className="back-button" icon={<FontAwesomeIcon icon={faTh} />} iconPosition={utils.Positions.Left}>
						All Courses
					</Button>
				</A>
			</div>
		</div>
	)
}

Banner.propTypes = {};

export default Banner;