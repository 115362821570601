import React from 'react';

import './styles.scss';

export const Types = {
	Text: 'Text',
	Divider: 'Divider',
	Button: 'Button'
}

const MenuItem = props => {
	// Variables and Functions
	const { type, tintColor } = props;

	// Render
	switch (type) {
		case Types.Divider:
			return (
				<div className="MenuItem Divider">
				</div>
			)
		case Types.Button:
			return (
				<div className="MenuItem Button" style={{ ...props.style, backgroundColor: tintColor ? tintColor : 'white' }} onClick={_ => {
					if (props.onClick) {
						props.onClick()
					}
				}}>
					{props.children}
				</div>
			)
		case Types.Text:
		default:
			return (
				<div className="MenuItem" style={{ ...props.style }} onClick={_ => {
					if (props.onClick) {
						props.onClick()
					}
				}}>
					{props.children}
				</div>
			)
	}
}

MenuItem.propTypes = {
};

export default MenuItem;