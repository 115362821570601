import React from 'react';
import './styles.scss';


const Score = props => {
	// Variables and Functions
	// const headerSizes = {
	// 	small: 12,
	// 	medium: 18,
	// 	large: 24,
	// }
	// const pSizes = {
	// 	small: 24,
	// 	medium: 36,
	// 	large: 56,
	// }

	// Render
	return (
		<div className={`Score ${props.frameSize}`} style={{ ...props.style }}>
			<h3>{props.title || "SCORE"}</h3>
			<p>{props.children}</p>
		</div>
	)
}

Score.propTypes = {};

export default Score;