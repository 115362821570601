import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useCourses } from "../../hooks/useCourses";
import './styles.scss';


const Tile = props => {

    const { getCourseImageURL } = useCourses();
    const [courseLogo, setCourseLogo] = useState(null);
    const [featuredImage, setFeaturedImage] = useState(null);

    useEffect(() => {
        if (props.course) {
            let imageKey = "courseLogo";
            let size = 0;
            if (props.course[imageKey]) {
                getCourseImageURL(props.course, imageKey, size, url => {
                    setCourseLogo(url);
                })
            }
            imageKey = "featuredImage";
            size = 0;
            if (props.course[imageKey]) {
                getCourseImageURL(props.course, imageKey, size, url => {
                    setFeaturedImage(url);
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.course])

    return (
        <div className="Tile">
            {featuredImage ?
                <img className="tile-background" src={featuredImage} alt="Course Featured Landscape" />
                : null}
            <div className={`tile-overlay${featuredImage ? "" : " light"}`}></div>
            <div className="sponsor">
                {courseLogo ?
                    <img src={courseLogo} alt="Course Sponsor Logo"></img>
                    :
                    <h1>{props.course.courseName}</h1>
                }
            </div>
        </div>
    );
};

Tile.defaultProps = {
    course: {
        courseName: '',
        courseImage: '',
        sponsorLogo: ''
    }
};

Tile.propTypes = {
    course: PropTypes.object
};

export default Tile;