import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

export function useTokens() {

    const getTokens = async _ => {
        let tokens = [];
        let error = null;
        const uid = firebase.auth().currentUser.uid;
        const checkTokens = firebase.firestore().collection('Tokens')
            .where('userId', '==', uid)
            .where('redeemed', '==', false);
        await checkTokens.get().then(snap => {
            let validTokens = [];
            snap.docs.forEach(doc => {
                let token = doc.data();
                token.id = doc.id;
                validTokens.push(token);
            })
            validTokens = validTokens.sort((docA, docB) => {
                return docA.worth < docB.worth;
            });
            console.log("VALID TOKENS: ", validTokens);
            tokens = validTokens;
        }).catch(err => {
            console.log("ERROR: ", err)
            console.log('NO TOKENS FOUND')
            error = err;
        })
        return [tokens, error];
    }

    return [
        getTokens
    ]
}