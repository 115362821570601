// import { object } from 'firebase-functions/lib/providers/storage';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { useEffect, useState } from "react";
import utils from '../scripts/Utilities';

export function useCourses() {

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        getCourses()
    }, [])

    const getCourses = _ => {
        firebase.firestore().collection('fl_content')
            .where('_fl_meta_.schema', '==', 'course')
            .get()
            .then(snap => {
                let cs = [];
                snap.docs.forEach(doc => {
                    cs.push(doc.data());
                })
                setCourses(cs);
                // getCourseLogos(cs)
            })
            .catch(err => {
                console.log("Error getting documents: ", err);
            })
    }

    const getCourseImageURL = async (course, imageKey, size, callback) => {
        course[imageKey][0].get().then(doc => {
            const data = doc.data();
            const sizedFolder = data.sizes[size].path;
            firebase.storage().ref(`flamelink/media/sized/${sizedFolder}/${data.file}`).getDownloadURL().then(url => {
                callback(url);
            })
        })
    }

    const getCourseImageURLs = async (course, imageKey, size, imagesLoadedCallback) => {
        let promises = [];
        for (var i = 0; i < course[imageKey].length; i++) {
            promises.push(
                course[imageKey][i].get()
            )
        }
        Promise.all(promises).then(r => {
            let urls = []
            let processResults = async results => {
                if (results) {
                    await utils.asyncForEach(results, async (doc, i) => {
                        const data = doc.data();
                        const sizedFolder = data.sizes[size].path;
                        // console.log("path: ", `flamelink/media/sized/${sizedFolder}/${data.file}`);
                        await firebase.storage().ref(`flamelink/media/sized/${sizedFolder}/${data.file}`).getDownloadURL().then(url => {
                            urls.push(url);
                        })
                    })
                }
                imagesLoadedCallback(urls);
            }
            processResults(r)
            // Promise.all(morePromises).then(urlResults => {
            //     console.log(urlResults)
            //     if (urlResults) {
            //         imagesLoadedCallback(urlResults)
            //     }
            // })
        })
        // course[imageKey][0].get().then(doc => {
        //     const data = doc.data();
        //     const sizedFolder = data.sizes[size].path;
        //     firebase.storage().ref(`flamelink/media/sized/${sizedFolder}/${data.file}`).getDownloadURL().then(url => {
        //         imageLoadedCallback(url);
        //     })
        // })
    }

    return {
        courses,
        getCourses,
        getCourseImageURL,
        getCourseImageURLs
    }

}