
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAxeCO-9b93qzI0U6QLbkWmhCFPgZY__cU",
  authDomain: "teetogreen-b4644.firebaseapp.com",
  databaseURL: "https://teetogreen-b4644.firebaseio.com",
  projectId: "teetogreen-b4644",
  storageBucket: "teetogreen-b4644.appspot.com",
  messagingSenderId: "773868486029",
  appId: "1:773868486029:web:3cce07746482f2dbee11c4",
  measurementId: "G-CEJQQXTZ8D"
};

const STRIPE_PUBLISHABLE_KEY = 'pk_live_51HtyxhKUAt2VxbSOlCCQh8C4g6S4Yc5mZ0aBuUc0Noz5nlpJTt7Q5WxmZqoKQtqs9PPZeiiOUxovm66EaJwctoIw00zcxPKvx5';
let currentUser = {};
let customerData = {};

const FH = {

  // const [app, setApp] = useState(null);
  // const [db, setDb] = useState(null);

  // useEffect(() => {
  //     initialize()
  // }, [])

  // checkUser: _=>{
  //     firebase.auth().onAuthStateChanged((firebaseUser) => {
  //         if (firebaseUser) {
  //           currentUser = firebaseUser;
  //           firebase
  //             .firestore()
  //             .collection('stripe_customers')
  //             .doc(currentUser.uid)
  //             .onSnapshot((snapshot) => {
  //               if (snapshot.data()) {
  //                 customerData = snapshot.data();
  //                 console.log('yooo going thru' + customerData)
  //               } else {
  //                 console.warn(
  //                   `No Stripe customer found in Firestore for user: ${currentUser.uid}`
  //                 );
  //               }
  //             });
  //         } else {
  //           document.getElementById('content').style.display = 'none';
  //           console.log('user not found, sign in')
  //         }
  //       });
  //     },

  /**
   * Set up Firestore data listeners
   */


  Initialize: _ => {
    firebase.initializeApp(firebaseConfig);
    firebase.firestore().enablePersistence()
      .catch(function (err) {
        if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
        }
      });
    // firebase.auth().onAuthStateChanged((firebaseUser) => {
    //   if (firebaseUser) {
    //     currentUser = firebaseUser;
    //     firebase
    //       .firestore()
    //       .collection('stripe_customers')
    //       .doc(currentUser.uid)
    //       .get()
    //       .then(doc => {

    //         let prof = doc.data()
    //         prof.id = doc.id
    //         console.log(prof)
    //       })
    //       .catch(err => {
    //         console.log("Error getting user profile: ", err);
    //       })
    //   } else {
    //     document.getElementById('content').style.display = 'none';
    //     console.log('user not found, sign in')
    //   }
    // });
    // FH.ref = firebase.database().ref();

    // const idb = window.indexedDB;

    // let dbPromise = idb.open('TeeToGreen', 1, function (upgradeDb) {
    //     if (!upgradeDb.objectStoreNames.contains('appData')) {
    //         upgradeDb.createObjectStore('appData');
    //     } else {
    //     }
    // })
  }
}

export default FH;