import React, { useEffect, useState } from 'react';
import FH from '../../scripts/FireHelper'
import * as firebase from 'firebase/app';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useProfile } from "../../hooks/useProfile";
import { formatAmountForStripe, customerData } from '../../scripts/stripe';
import styles from './styles.module.scss';
import axios from 'axios';

const CheckoutForm = ({ success, dismiss, credits, amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [checkoutErrorMsg, setCheckoutErrorMsg] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)
  const [user, loadingUser, errorUser] = useAuthState(firebase.auth())

  // console.log(checkoutErrorMsg)
  const handleChange = (e) => {
    // console.log(e)
    if (e.error) {
      setCheckoutErrorMsg(e.error.message)
    }
    setCheckoutErrorMsg("")
  }
  // console.log(customerData)
  // commented out for other console testing
  const handleSubmit = async event => {
    event.preventDefault()
    // elements.getElement(CardElement).clear();
    setIsProcessing(true)
    const { error, setupIntent } = await stripe.confirmCardSetup(
      `${customerData.setup_secret}`,
      {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: 'andres',
          },
        },
      })

    // await console.log(setupIntent)
    // console.log(setupIntent)
    // console.log(error)

    if (!error) {
      try {
        await firebase
          .firestore()
          .collection('stripe_customers')
          .doc(user.uid)
          .collection('payment_methods')
          .add({ id: setupIntent.payment_method });
        const data = {
          payment_method: setupIntent.payment_method,
          currency: 'usd',
          amount: formatAmountForStripe(amount, 'usd'),
          status: 'new',
          tokensCount: credits
        };

        await firebase
          .firestore()
          .collection('stripe_customers')
          .doc(user.uid)
          .collection('payments')
          .add(data);
        success();
        dismiss()
      } catch (error) {
        console.log(error)
      }
    } else if (error) {
      setIsProcessing(false)
    }

  };

  const CardOptions = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: 'rgba(0,0,0, 0.5)',
        color: 'rgba(0,0,0, 0.5)',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': { color: 'rgba(0,0,0, 0.5)' },
        '::placeholder': { color: 'rgba(0,0,0, 0.5)' },
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee',
      },
    },
  };
  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        className={styles.StripeStyle}
        onChange={handleChange}
        options={CardOptions} />
      <p>{checkoutErrorMsg}</p>
      <div className={styles.ButtonContain}>
        <button className={styles.BuyNow} type='submit' disabled={isProcessing}>
          {!isProcessing ? "Buy Now" : "Processing.."}
        </button>
      </div>
    </form>
  )
}

const stripePromise = loadStripe('pk_live_51HtyxhKUAt2VxbSOlCCQh8C4g6S4Yc5mZ0aBuUc0Noz5nlpJTt7Q5WxmZqoKQtqs9PPZeiiOUxovm66EaJwctoIw00zcxPKvx5');

const CrediTokenCard = ({ dismiss, credits, priceAmount }) => {
  const [status, setStatus] = useState('ready');
  // console.log(status)
  return (
    <div>
      <Elements stripe={stripePromise}>
        <CheckoutForm credits={credits} amount={priceAmount} dismiss={() => { dismiss() }} success={() => {
          // console.log('success')
        }} /></Elements>
    </div>
  )
}
export default CrediTokenCard;