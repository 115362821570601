import { IonSpinner } from "@ionic/react";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import React, { useState } from 'react';
import { Button, PopupBase } from '..';
import './styles.scss';

const SignIn = props => {
	// Variables and Functions
	const [mail, setMail] = useState("")
	const [pass, setPass] = useState("")

	const [working, setWorking] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

	const _handleMailChanged = e => {
		setMail(e.target.value)
	}

	const _handlePassChanged = e => {
		setPass(e.target.value)
	}

	const _handleKeyDown = e => {
		if (e.key === 'Enter') {
			signIn();
		}
	}

	const signIn = _ => {
		setWorking(true);
		firebase.auth().signInWithEmailAndPassword(mail, pass).then(cred => {
			setErrorMessage(null);
			dismiss("Successfully signed in!")
		}).catch(err => {
			setErrorMessage(err.message);
			setWorking(false);
		});
	}

	const dismiss = message => {
		if (props.onDismiss && !working) {
			if (message) {
				props.onDismiss(message)
			} else {
				props.onDismiss()
			}
			setWorking(false);
		}
	}

	// Render
	return (
		<PopupBase>
			{working ?
				<div className="SignIn" style={{ ...props.style, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
					<h1>Signing In</h1>
					<IonSpinner name="bubbles" style={{ transform: "scale(1.2)" }} />
				</div>
				:
				<div className="SignIn" style={{ ...props.style }}>
					<div className="SignInFlex">
						<h1>Sign In</h1>
						{errorMessage ?
							<div className="error-message">Error: {errorMessage}</div>
							: null}
						<input type="text" placeholder="Email" value={mail} onChange={_handleMailChanged} onKeyDown={_handleKeyDown} />
						<input type="password" placeholder="Password" value={pass} onChange={_handlePassChanged} onKeyDown={_handleKeyDown} />
						<Button onClick={_ => {
							signIn();
						}}>Sign In</Button>
						<Button onClick={_ => {
							dismiss()
						}} style={{ backgroundColor: "tomato" }}>Cancel</Button>
						<div className="no-account-container">
							<p>Don't have an account? </p>
							<Button onClick={_ => {
								if (props.onShowSignUp) {
									props.onShowSignUp();
								}
							}}>Sign Up</Button>
						</div>
						<div className="no-account-container">
							<p>Forgot your password? </p>
							<Button onClick={_ => {
								if (props.onShowForgotPassword) {
									props.onShowForgotPassword();
								}
							}}>Reset</Button>
						</div>
					</div>
				</div>
			}
		</PopupBase>
	)
}

SignIn.propTypes = {};

export default SignIn;