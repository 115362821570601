import { faBinoculars, faCalculator, faExternalLinkAlt, faFlag, faPencilAlt, faStar, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as firebase from "firebase/app";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import 'firebase/firestore';
import { navigate } from "hookrouter";
import React, { useEffect, useMemo, useState } from 'react';
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import ReactMarkdown from "react-markdown";
import {
	Banner,
	Button,
	ContentPanel,
	Divider,
	Header,
	Image,
	SkeletonText,
	Subtitle,
	Title,
	ConfirmPopup,
	PurchaseCreditsPopup
} from '../../components';
import { useCourses } from '../../hooks/useCourses';
import { useProfile } from "../../hooks/useProfile";
import './styles.scss';
import BallAndTeesImage from '../../images/ballandtees.png';
import { useTokens } from "../../hooks/useTokens";

const CourseDetails = props => {
	// Variables and Functions
	const { userInfo, profileInfo } = useProfile()
	// console.log(profileInfo)
	const { courseID } = props;
	const [courseData, courseDataLoading] = useDocumentDataOnce(firebase.firestore().collection("fl_content").doc(courseID))
	const [courses, coursesLoading] = useCollectionDataOnce(firebase.firestore().collection("fl_content").where('_fl_meta_.schema', '==', 'course'), { idField: "id" });
	const [loadingImages, setLoadingImages] = useState(false)
	const { getCourseImageURL, getCourseImageURLs } = useCourses();
	const [courseLogo, setCourseLogo] = useState(null);
	const [gallery, setGallery] = useState(null);
	const [featuredImage, setFeaturedImage] = useState(null);
	const courseLoading = courseDataLoading || !courseLogo || !featuredImage;
	const [showPlayPopup, setShowPlayPopup] = useState(false);
	const [showTokensOutPopup, setShowTokensOutPopup] = useState(false);
	const [showPurchasePopup, setShowPurchasePopup] = useState(false);
	const [validToken, setValidToken] = useState(null)
	const [getTokens] = useTokens();
	// const courseLoading = true;
	// console.log(courseID)
	// console.log(validToken)
	const [leaderboardLoading, setLeaderboardLoading] = useState(true);
	const [topPlayer, setTopPlayer] = useState("");

	useEffect(() => {
		// console.log("Profile Info: ", profileInfo)
	}, [profileInfo])

	let quickFacts = [
		{ "Holes": { icon: faFlag, value: courseData && courseData.numberOfHoles ? `${courseData.numberOfHoles}` : null } },
		{ "Par": { icon: faPencilAlt, value: courseData && courseData.par ? `${courseData.par}` : null } },
		{ "Length": { icon: faBinoculars, value: courseData && courseData.courseLength ? `${courseData.courseLength} yards` : null } },
		{ "Slope": { icon: faCalculator, value: courseData && courseData.courseSlope ? `${courseData.courseSlope}` : null } },
		{ "Rating": { icon: faStar, value: courseData && courseData.rating ? `${courseData.rating}` : null } },
	]
	let addInfo = [
		{ "Year Built": { value: courseData && courseData.yearBuilt ? `${courseData.yearBuilt}` : null } },
		{ "Greens": { value: courseData && courseData.greens ? `${courseData.greens}` : null } },
		{ "Fairways": { value: courseData && courseData.fairways ? `${courseData.fairways}` : null } },
		{ "Season": { value: courseData && courseData.season ? `${courseData.season}` : null } },
	]

	useEffect(() => {
		if (courseData && !loadingImages) {
			setLoadingImages(true);
			let imageKey = "courseLogo";
			let size = 1;
			if (courseData[imageKey]) {
				getCourseImageURL(courseData, imageKey, size, url => {
					setCourseLogo(url);
				})
			}
			imageKey = "featuredImage";
			size = 0;
			if (courseData[imageKey]) {
				getCourseImageURL(courseData, imageKey, size, url => {
					setFeaturedImage(url);
				})
			}
			imageKey = "additionalImages";
			size = 0;
			// console.log("Additional Images: ", courseData.additionalImages)
			if (courseData.additionalImages) {
				getCourseImageURLs(courseData, imageKey, size, urls => {
					// console.log(urls)
					setGallery(urls);
				})
			}
		}
		// if (courseData) {
		// 	setGallery(courseData.imageDeck);
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
		getTopPlayer()
		console.log(courseData ? courseData.aboutTheCourse : "No Course Data")
	}, [courseData])

	const getTopPlayer = _ => {
		if (courseData && courseData.id) {
			firebase.firestore().collection('Tokens').where('redeemedInfo.courseID', '==', courseID).orderBy('redeemedInfo.score', 'desc').get().then((snap) => {
				if (snap.docs.length > 0) {
					let doc = snap.docs[0];
					let data = doc.data();
					if (data.userId && data.userId.length > 0) {
						firebase.firestore().collection('UserProfiles').doc(data.userId).get().then((profSnap) => {
							setTopPlayer(profSnap.data().username)
							setLeaderboardLoading(false)
						})
					}
				}
			})
		}
	}

	const _renderQuickFact = (fact, index) => {
		const title = Object.keys(fact)[0];
		const { icon, value } = Object.values(fact)[0];

		if (courseLoading) {
			return (
				<div className="quick-fact" key={title}>
					{/* <FontAwesomeIcon icon={icon} style={{ fontSize: 14 }} /> */}
					<SkeletonText lineHeight={14} style={{ width: 14 }} />
					<SkeletonText lineHeight={16} style={{ width: 75 }} />
					<SkeletonText lineHeight={16} style={{ width: 50, justifySelf: 'flex-end' }} />
				</div>
			)
		}

		if (!value || value.length < 1) {
			return null
		}

		return (
			<div className="quick-fact" key={title}>
				<FontAwesomeIcon icon={icon} style={{ fontSize: 14 }} />
				<p className="title">{title}:</p>
				<p className="value">{value}</p>
			</div>
		)
	}

	const _renderAddInfo = (fact, index) => {
		const title = Object.keys(fact)[0];
		const { value } = Object.values(fact)[0];

		if (courseLoading) {
			return (
				<div className="add-info" key={title}>
					<SkeletonText lineHeight={16} style={{ width: 75 }} />
					<SkeletonText lineHeight={16} style={{ width: 50, justifySelf: 'flex-end' }} />
				</div>
			)
		}

		if (!value || value.length < 1) {
			return null
		}

		return (
			<div className="add-info" key={title}>
				<p className="title">{title}:</p>
				<p className="value">{value}</p>
			</div>
		)
	}

	const _renderGalleryImage = props => {
		const [galleryLoading, setGalleryLoading] = useState(true);

		return (
			<div>
				{galleryLoading ?
					<SkeletonText lineHeight={300} style={{ maxWidth: `600px`, borderRadius: 12, marginTop: 24, marginBottom: 24 }} />
					:
					<img
						src={props.src}
						alt={props.alt}
						style={{}}
						onLoad={() => { setGalleryLoading(false) }}
					/>
				}
			</div>
		);
	};

	const _renderMarkupImage = props => {
		const [imageLoading, setImageLoading] = useState(true);

		return (
			<div>
				{imageLoading ?
					<SkeletonText lineHeight={300} style={{ maxWidth: `600px`, borderRadius: 12, marginTop: 24, marginBottom: 24 }} />
					: null}
				<img
					style={{ width: '50%', display: imageLoading ? "none" : "inline-block" }}
					alt={props.alt}
					src={props.src}
					onLoad={_ => { setImageLoading(false) }}
				/>
			</div>
		);
	};

	const renderers = {
		image: _renderMarkupImage
	}

	const onImageLoaded = (success, error) => {
		if (!success) {
			console.log(error);
		}
	}

	const tokenCount = useMemo(() => _ => {
		if (!profileInfo || !profileInfo[0] || !profileInfo[0].tokenCount) {
			return 0
		}
		return profileInfo[0].tokenCount
	}, profileInfo)

	// Render
	return (
		<div className="CourseDetails">
			<Header userInfo={userInfo} profileInfo={profileInfo} courses={courses}>Play Tee to Green</Header>
			<div className="content">
				<Banner logo={courseLogo}
					featured={featuredImage}
					loading={courseLoading} />
				<div className="body">
					<div className="max-width">
						<ContentPanel className="main-content" style={{ paddingTop: 36, paddingBottom: 36 }}>
							<div className="title-container">
								<Title loading={courseLoading}>{courseLoading ? "" : courseData.courseName}</Title>
								{courseLoading ?
									<SkeletonText lineHeight={48} style={{ maxWidth: 150, width: 150, marginBottom: 24 }} />
									:
									<a href={courseData.courseWebsite} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
										<Button icon={<FontAwesomeIcon icon={faExternalLinkAlt} />}>Visit Website</Button>
									</a>
								}
							</div>
							{courseLoading ?
								<div className="about-section">
									{[75, 86, 93, 82, 68, 72, 90, 85, 87, 73, 95, 0, 75, 86, 93, 82, 68, 72, 90, 85, 87, 73, 95].map((itemWidth, i) => {
										return (
											<SkeletonText key={i} lineHeight={16} style={{ maxWidth: `${itemWidth}%` }} />
										)
									})}
								</div>
								:
								<div className="about-section">
									<ReactMarkdown children={courseData.aboutTheCourse} renderers={renderers} />
								</div>
							}
							<Title loading={courseLoading}>Images</Title>
							<div className="image-scroller">
								<div className="images">

									{featuredImage ?
										<Image onLoad={onImageLoaded} src={featuredImage} alt="Golf Course Landscape" />
										: null}
									{gallery ?
										gallery.map(url => {
											return (
												<Image key={url} onLoad={onImageLoaded} src={url} alt="Golf Course Landscape" />
											)
										})
										: null}

									{/* {gallery.map(_renderGalleryImage)} */}
									<div></div>
									<div className="end-spacer"></div>

								</div>
							</div>
						</ContentPanel>
						{/* <div className="side-panel"> */}
						<ContentPanel small className="play-now">
							<img src={BallAndTeesImage} className="background-image" />
							<div className="background-overlay"></div>
							<Subtitle loading={courseLoading}>Play This Course</Subtitle>
							<Divider />
							{courseLoading ?
								<SkeletonText lineHeight={64} style={{ maxWidth: '100%' }} />
								:
								// <A href={`/courses/${props.courseID}/play`} style={{ textDecoration: 'none' }}>
								<Button highlight
									disabled={firebase.auth().currentUser == null}
									icon={<FontAwesomeIcon icon={faTicketAlt} style={{ fontSize: 24 }} />}
									style={{ padding: 18, fontSize: 24 }} onClick={_ => {
										if (tokenCount() > 0) {
											if (firebase.auth().currentUser != null) {
												setShowPlayPopup(true);
											}
										} else {
											if (firebase.auth().currentUser != null) {
												setShowTokensOutPopup(true);
											}
										}
									}}>Play Now</Button>
								// </A>
							}
						</ContentPanel>
						<ContentPanel small className="quick-facts">
							<Subtitle loading={courseLoading}>Top Player</Subtitle>
							<Divider />
							{/* Top Players */}
							<div style={{ backgroundColor: 'rgba(255,255,255,0.2)', padding: 12, borderRadius: 12, marginBottom: 24 }}>
								{leaderboardLoading && <SkeletonText lineHeight={36} style={{ maxWidth: '100%' }} />}
								{!leaderboardLoading && <p style={{ maxWidth: '100%', textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>{topPlayer}</p>}
							</div>

							<Subtitle loading={courseLoading}>Quick Facts</Subtitle>
							<Divider />
							{/* Quick Facts */}
							{quickFacts.map(_renderQuickFact)}
							<Subtitle loading={courseLoading} style={{ paddingTop: 12 }}>Additional Info</Subtitle>
							<Divider />
							{/* Additional Info */}
							{addInfo.map(_renderAddInfo)}
						</ContentPanel>
						{/* </div> */}
					</div>
				</div>
			</div>
			{showPlayPopup ?
				<ConfirmPopup title="Continue?"
					message={<div>By clicking '<strong>Play Now</strong>' below, you agree to use one of your available credits.  This cannot be undone.<br /><br />Current Credits: {tokenCount()}<br />Credits after Playing: {tokenCount() - 1}</div>}
					buttonTitle="Play Now"
					onConfirm={async () => {
						const [tokens, error] = await getTokens();
						if (!error && tokens.length > 0) {
							let tokenID = tokens[0].id;
							setValidToken(tokenID)
							navigate(`/courses/${props.courseID}/play/${tokenID}`);
						} else {
							setShowTokensOutPopup(true)
						}
					}}
					onDismiss={_ => {
						setShowPlayPopup(false);
					}} />
				: null}
			{showTokensOutPopup ?
				<ConfirmPopup title="Uh oh..."
					message={<div>Looks like you don't have any credits left.  Would you like to purchase more?</div>}
					buttonTitle="Purchase Credits"
					onConfirm={_ => {
						setShowTokensOutPopup(false);
						setShowPurchasePopup(true)
						// TODO: Show Popup for Purchasing Credits
					}}
					onDismiss={_ => {
						setShowTokensOutPopup(false);
					}} />
				: null}
			{showPurchasePopup ?
				<PurchaseCreditsPopup loadingTitle="Processing Payment"
					loadingMessage="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy"
					title="Purchase Credits"
					message="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy"
					onDismiss={_ => {
						setShowPurchasePopup(false);
					}}
				/>
				: null}
		</div>
	)
}

CourseDetails.propTypes = {};

export default CourseDetails;