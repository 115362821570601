import React from 'react';
import { SkeletonText } from '..';
import './styles.scss';


const Title = props => {
	// Variables and Functions

	// Render
	return (
		<div className="Title" style={{ ...props.style }}>
			{props.loading ?
				<SkeletonText lineHeight={36} style={{ marginTop: 4, marginBottom: 24, maxWidth: "50%" }} />
				:
				<h1>
					{props.children}
				</h1>
			}
		</div>
	)
}

Title.propTypes = {};

export default Title;