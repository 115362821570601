import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonToast } from "@ionic/react";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import React, { useEffect, useState } from 'react';
import {
    HeaderButton,
    Menu,
    MenuItem,
    MenuItemTypes,
    ProfileMini,
    Search,
    SignIn,
    SignUp,
    ForgotPassword
} from '../../components';
import GolfBallImage from '../../images/ball.png';
import utils from 'synalgic-components/scripts/utils';
import './styles.scss';
import { BallImage } from '../../images';
import PurchaseCreditsPopup from '../PurchaseCreditsPopup'
import { A } from 'hookrouter';
import Settings from '../Settings';
import { useTokens } from '../../hooks/useTokens';

const Header = props => {
    const [user, loadingUser] = props.userInfo || [null, false, null];
    const [profile, loadingProfile] = props.profileInfo || [null, false, null];

    const [tokenCount, setTokenCount] = useState(null);
    const [showSignIn, setShowSignIn] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [showSettings, setShowSettings] = useState(false);
    const [showPurchasePopup, setShowPurchasePopup] = useState(false);
    const [screenWidth] = utils.useWindowSize();
    const [getTokens] = useTokens();
    const isCompact = _ => {
        const containerWidth = Math.min(screenWidth, 1400);
        if (containerWidth < 1000) {
            return true
        }
        return false
    }
    const compactStyle = {
        padding: 0
    }
    const isMicro = _ => {
        return screenWidth < 600
    }

    const menuItems = [
        {
            type: MenuItemTypes.Text,
            title: "Account Settings",
            action: _ => {
                // alert('Menu Item Clicked');  
                setShowSettings(true);
            }
        },
        {
            type: MenuItemTypes.Text,
            title: "Purchase Credits",
            action: _ => {
                setShowPurchasePopup(true)
                // alert('Menu Item Clicked');
            }
        },
        {
            type: MenuItemTypes.Divider,
            action: null
        },
        {
            type: MenuItemTypes.Button,
            title: "Log Out",
            action: _ => {
                firebase.auth().signOut();
            },
            tintColor: '#FF0000'
        }
    ]

    useEffect(_ => {
        if (profile !== null) {
            getTokenCount();
        }
    }, [profile])

    const getTokenCount = async _ => {
        const [tokens, error] = await getTokens();
        setTokenCount(tokens.length);
    }

    return (
        <>
            <div className="Header">
                <div className="header-content">
                    <A href="/">
                        <img className="header-logo" alt="Golf Ball Logo" src={GolfBallImage} />
                    </A>
                    {!isMicro() ?
                        <h1 className="header-title" style={{ fontSize: isCompact() ? 24 : 32 }}>{props.children}</h1>
                        :
                        <div></div>
                    }

                    {/* Search Button */}
                    <HeaderButton style={{ padding: 0 }} onClick={_ => {
                        if (!showSearch) {
                            setShowSearch(true);
                        }
                    }}>
                        <Search courses={props.courses} show={showSearch} onBlur={_ => {
                            setTimeout(() => {
                                setShowSearch(false);
                            }, 100);
                        }} />
                    </HeaderButton>

                    {/* Num of Plays Button */}
                    {!loadingUser && user && !loadingProfile && profile ?
                        <HeaderButton collapse={isCompact()} style={isCompact() ? compactStyle : {}}>
                            <div className={`num-of-plays-container${isCompact() ? ' collapse' : ''}`}>
                                {isCompact() ? `${tokenCount !== null ? tokenCount : "loading"}` : `Num of Plays: ${tokenCount !== null ? tokenCount : "loading"}`}
                            </div>
                        </HeaderButton>
                        : null}

                    {/* User Account Button */}
                    {!loadingUser && user && !loadingProfile && profile ?
                        <HeaderButton collapse={isCompact()} style={isCompact() ? compactStyle : { padding: 8, paddingRight: 12 }} onClick={_ => { setShowMenu(true) }}>
                            <div className={`profile-container${isCompact() ? ' collapse' : ''}`}>
                                <ProfileMini collapse={isCompact()} style={isCompact() ? compactStyle : {}} profile={{
                                    image: BallImage,
                                    name: profile.firstName ? `${profile.firstName}${profile.lastName ? ` ${profile.lastName}` : ""}` : "User Profile"
                                }} />
                            </div>
                        </HeaderButton>
                        : null}

                    {/* Sign In Button */}
                    {!loadingUser && !user ?
                        <HeaderButton onClick={_ => {
                            setShowSignIn(true);
                        }}>
                            <div className="sign-in-button">
                                <FontAwesomeIcon icon={faSignInAlt} />
                                <p>Sign In</p>
                            </div>
                        </HeaderButton>
                        : null}
                </div>
            </div>
            {showMenu ?
                <Menu onDismiss={_ => { setShowMenu(false) }}>
                    {menuItems.map((menuItem, i) => {
                        return (
                            <MenuItem key={i} onClick={menuItem.action} type={menuItem.type} tintColor={menuItem.tintColor}>
                                {menuItem.title}
                            </MenuItem>
                        )
                    })}
                </Menu>
                : null}
            {showSignIn ?
                <SignIn
                    onDismiss={message => {
                        setShowSignIn(false)
                        if (message) {
                            setToastMessage(message)
                        }
                    }}
                    onShowSignUp={_ => {
                        setShowSignIn(false)
                        setShowSignUp(true)
                    }}
                    onShowForgotPassword={_ => {
                        let forgot = prompt("Please enter your email", "sample@gmail.com");
                        if (forgot != "sample@gmail.com" || null || "" || " ") {
                            firebase.auth().sendPasswordResetEmail(forgot);
                            alert("We've sent you an email to assist in resetting your password");
                        }
                        else {
                            alert("There is no email in our database with that name");
                        }
                    }}
                />
                : null}
            {showSignUp ?
                <SignUp onDismiss={message => {
                    setShowSignUp(false)
                    if (message) {
                        setToastMessage(message)
                    }
                }}
                    onShowSignIn={_ => {
                        setShowSignIn(true)
                        setShowSignUp(false)
                    }} />
                : null}
            {showPurchasePopup ?
                <PurchaseCreditsPopup loadingTitle="Processing Payment"
                    loadingMessage="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy"
                    title="Purchase Credits"
                    message="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy"
                    onDismiss={_ => {
                        setShowPurchasePopup(false);
                    }}
                />
                : null}
            {showSettings ?
                <Settings
                    profile={profile}
                    saveOnClick={() => console.log('SaveValues')}
                    dismissOnClick={() => setShowSettings(false)}
                    openCreditsPopup={() => {
                        setShowSettings(false);
                        setShowPurchasePopup(true)
                    }}
                />
                : null}
            <IonToast isOpen={toastMessage !== null}
                message={toastMessage}
                onDidDismiss={_ => { setToastMessage(null) }}
                duration={2000} />
        </>
    );
};

Header.propTypes = {};

export default Header;