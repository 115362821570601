import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const TileContainer = props => {
    return (
        <div className="TileContainer">
            <div className="inner" style={{ gridTemplateColumns: `repeat(${props.numOfColumns}, 1fr)` }}>
                {props.children}
            </div>
        </div>
    );
};

TileContainer.defaultProps = {
    numOfColumns: 4
}

TileContainer.propTypes = {
    numOfColumns: PropTypes.number
};

export default TileContainer;