import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import React, { useEffect, useMemo, useState } from 'react';
import { useDocumentDataOnce, useDocumentData } from 'react-firebase-hooks/firestore';
import { Game, LoadingPopup } from '../../components';
import utils from '../../scripts/Utilities';
import './styles.scss';

const Play = props => {
    // Variables and Functions
    // const [course, loadingCourse, errorCourse] = [{

    // }, false, null]
    const [course, loadingCourse, errorCourse] = useDocumentDataOnce(firebase.firestore().collection("fl_content").doc(props.courseID), { idField: "id" })
    const [courseAddInfo, loadingCourseAddInfo, errorCourseAddInfo] = useDocumentData(firebase.firestore().collection("fl_content").doc(props.courseID).collection("AdditionalData").doc("holesPlayed"), { idField: "id" })
    // const [holes, setHoles] = useState([
    // 	{
    // 		id: "a",
    // 		holeImageURL: "https://firebasestorage.googleapis.com/v0/b/teetogreen-b4644.appspot.com/o/flamelink%2Fmedia%2Fsized%2F1080_9999_100%2FHole%201%20Fuzzy%20Zoeller%201.png?alt=media&token=4fd57df0-7525-4076-8854-0eff84acb06f",
    // 		maximumStrokes: 5,
    // 		minimumStrokes: 2,
    // 		par: 3
    // 	},
    // ])
    const [holes, setHoles] = useState(null)
    const [loadingHoles, setLoadingHoles] = useState(false)
    const [errorHoles, setErrorHoles] = useState(null)

    const showLoading = loadingCourse || loadingHoles

    const [loadingMessage, setLoadingMessage] = useState("Loading golf course...")
    const [loadingProgress, setLoadingProgress] = useState(0)

    useEffect(_ => {
        if (!loadingCourse && course && !loadingHoles && !holes) {
            setLoadingProgress(10) // 10% for loading the course
            setLoadingHoles(true)
            getHoles(course)
        }
    }, [loadingCourse, course, loadingHoles, holes])

    const errors = useMemo(_ => _ => {
        let es = []
        if (errorCourse) {
            es.push(errorCourse)
        }
        if (errorHoles) {
            es = [...es, ...errorHoles]
        }
        return es
    }, [errorCourse, errorHoles])

    useEffect(() => {
        if (course && courseAddInfo && holes) {
            const holesPlayed = courseAddInfo.holesPlayed || 1;
            const minorHolesInOne = course.minorHolesInOne || 150;
            const majorHolesInOne = course.majorHolesInOne || 500;
            const minorHoleInOne = holesPlayed % minorHolesInOne === 0;
            const majorHoleInOne = holesPlayed % majorHolesInOne === 0;
            // console.log('Minor Hole In One: ', minorHoleInOne)
            // console.log('Major Hole In One: ', majorHoleInOne)

            holes.forEach((hole, i) => {
                hole.minorHoleInOne = minorHoleInOne;
                hole.majorHoleInOne = majorHoleInOne;
            })
        }
    }, [course, courseAddInfo, holes])

    const getHoles = async crs => {

        // Handle getting course holes
        let allHoles = [];
        let holeErrors = [];
        const totalItems = (crs.holes.length * 2) + 1
        let currentItem = 1;
        const imageSize = 3;
        await utils.asyncForEach(crs.holes, async (h, i) => {
            try {
                // Load hole
                currentItem++;
                setLoadingMessage(`Loading hole ${i + 1}\n(Resources loaded: ${currentItem}/${totalItems})`)
                setLoadingProgress(100 * (currentItem / totalItems))
                const doc = await h.get()
                let hole = doc.data()
                if (!hole) {
                    holeErrors.push(`Failed to load hole ${i + 1}`)
                } else {
                    // Load hole images
                    currentItem++;

                    setLoadingMessage(`Loading image for hole ${i + 1}\n(Resources loaded: ${currentItem}/${totalItems})`)
                    setLoadingProgress(100 * (currentItem / totalItems))
                    const imageDoc = await hole.holeImage[0].get()
                    let hImage = imageDoc.data()
                    if (!hImage) {
                        holeErrors.push(`Failed to load image for hole ${i + 1}`)
                    } else {
                        const sizedFolder = hImage.sizes[imageSize].path;
                        const url = await firebase.storage().ref(`flamelink/media/sized/${sizedFolder}/${hImage.file}`).getDownloadURL()
                        if (!url) {
                            holeErrors.push(`Failed to load image for hole ${i + 1}`)
                        } else {
                            hole.holeImageURL = url;
                        }
                    }

                    if (!hole.startingAudio) {
                        console.log('not good to go');
                        //const audioDoc = await hole.startingAudio[0].get()
                        // let hAudio = audioDoc.data()
                    } else {
                        const audioDoc = await hole.startingAudio[0].get()
                        let hAudio = audioDoc.data()
                        const audioURL = await firebase.storage().ref(`flamelink/media/${hAudio.file}`).getDownloadURL()
                        if (!audioURL) {
                            holeErrors.push(`Failed to load image for hole ${i + 1}`)
                        } else {
                            hole.holeAudioURL = audioURL;
                        }
                    }

                    // Done loading hole image
                }
                // Done loading hole

                allHoles.push(hole)
            } catch (error) {
                holeErrors.push(error.message)
            }
        })
        setErrorHoles(holeErrors)
        setHoles(allHoles)
        setLoadingHoles(false)
    }

    // Render
    return (
        <div className="Play"
            style={{
                ...props.style,
                // backgroundImage: `url('${require('../../images/grass.jpg')}')`
            }}>
            <img className="play-background" src={require('../../images/grass-dark.jpg')} />
            {!showLoading && course && holes && holes.length > 0 ?
                <Game course={course} holes={holes} keyId={props.keyID} />
                : null}
            {showLoading ?
                <LoadingPopup title={"Starting Game"} message={loadingMessage} progress={loadingProgress} errors={errors} />
                : null}
        </div>
    )
}

Play.propTypes = {};

export default Play;