import { IonSpinner } from "@ionic/react";
import 'firebase/auth';
import React, { useState } from 'react';
import { Button, PopupBase } from '..';
import './styles.scss';

const ConfirmPopup = props => {
	// Variables and Functions
	const [working, setWorking] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null)

	const doWork = _ => {
		// setWorking(true);


		// On finish work
		if (props.onConfirm && !working) {
			props.onConfirm()
			setWorking(false);
		}
	}

	const dismiss = message => {
		if (props.onDismiss && !working) {
			if (message) {
				props.onDismiss(message)
			} else {
				props.onDismiss()
			}
			setWorking(false);
		}
	}

	// Render
	return (
		<PopupBase>
			{working ?
				<div className="ConfirmPopup" style={{ ...props.style, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
					<h1>{props.loadingTitle}</h1>
					<p>{props.loadingMessage}</p>
					<IonSpinner name="bubbles" style={{ transform: "scale(1.2)" }} />
				</div>
				:
				<div className="ConfirmPopup" style={{ ...props.style }}>
					<div className="ModalContainer">
						<h1>{props.title}</h1>
						<p>{props.message}</p>
						<div className="buttons-container">
							<Button onClick={_ => {
								dismiss()
							}} style={{ backgroundColor: "tomato", marginTop: 0 }}>{props.cancelTitle || "Cancel"}</Button>
							<Button style={{ color: props.buttonColor }} onClick={_ => {
								doWork()
							}}>{props.buttonTitle}</Button>
						</div>
					</div>
				</div>
			}
		</PopupBase>
	)
}

ConfirmPopup.propTypes = {};

export default ConfirmPopup;