import React from 'react';
import './styles.scss';


const Menu = props => {
	// Variables and Functions

	// Render
	return (
		<>
			<div className="menu-backdrop" onClick={_ => { props.onDismiss() }}>
				<div className="menu-container">
					<div className="menu" style={{ ...props.style }}>
						{props.children}
					</div>
				</div>
			</div>ß
		</>
	)
}

Menu.propTypes = {};

export default Menu;