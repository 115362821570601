import React from 'react';
import './styles.scss';


const Divider = props => {
	// Variables and Functions

	// Render
	return (
		<div className="Divider" style={{ ...props.style }}>
		</div>
	)
}

Divider.propTypes = {};

export default Divider;