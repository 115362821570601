import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { useCourses } from "../../hooks/useCourses";

const SearchResultItem = props => {

    const { getCourseImageURL } = useCourses();
    const [courseLogo, setCourseLogo] = useState(null);
    const [featuredImage, setFeaturedImage] = useState(null);

    useEffect(() => {
        if (props.course) {
            let imageKey = "courseLogo";
            let size = 0;
            if (props.course[imageKey]) {
                getCourseImageURL(props.course, imageKey, size, url => {
                    setCourseLogo(url);
                })
            }
            imageKey = "featuredImage";
            size = 0;
            if (props.course[imageKey]) {
                getCourseImageURL(props.course, imageKey, size, url => {
                    setFeaturedImage(url);
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.course]);

    return (
        <div className={styles.SearchResultItem}>
            <div className={styles.image} style={{backgroundImage: "url(" + featuredImage + ")"}}>
                <img src={courseLogo} alt="coursePreview"></img>
            </div>
            <div className={styles.information}>
                <h2 className={styles.title}>{props.CourseTitle}</h2>
                <p className={styles.descript}>{props.CourseDescripton}</p>
            </div>
        </div>
    );
};

SearchResultItem.propTypes = {
    CourseTitle: PropTypes.string.isRequired,
    CourseDescription: PropTypes.string.isRequired,
};

export default SearchResultItem;