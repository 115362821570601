import React, { useRef, useEffect, useState } from 'react';
import * as firebase from "firebase/app";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { A } from 'hookrouter';
import utils from 'synalgic-components/scripts/utils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import SearchResultItem from '../../components/SearchResultItem';
import { useCourses } from '../../hooks/useCourses';

import './styles.scss';

const Search = props => {

    // Variables and Functions
    const searchRef = useRef(null)
    const [screenWidth] = utils.useWindowSize();
    const [coursesShown, setCoursesShown] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [data, setData] = useState(props.courses);

    const excludeColumns = [
        //USE 'aboutTheCourse',
        'courseLength',
        'courseLengthYards',
        'courseLogo',
        //USE 'courseName', 
        'courseSlope',
        'courseWebsite',
        'enabled',
        'fairways',
        //USE 'featuredImage', 
        'greens',
        'holes',
        'id',
        'numberOfHoles',
        'order',
        'par',
        'parentId',
        'rating',
        'season',
        'yearBuilt',
        '_fl_meta_',
        '__proto__'];
    useEffect(() => {
        if (searchRef.current) {
            let max = 400;
            if (screenWidth < 1250) {
                max = 300;
            }
            searchRef.current.style.setProperty('--max-width', max + "px")
        }
    }, [screenWidth])

    const handleChange = value => {
        setSearchInput(value);
        filterData(value);
    };

    const filterData = (value) => {
        const lowercasedValue = value.toLowerCase().trim();
        if (lowercasedValue === "") setData(props.courses);
        else {
            const filteredData = props.courses.filter(item => {
                return Object.keys(item).some(key =>
                    excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
                );
            });
            setData(filteredData);
        };
    };

    return (
        <div ref={searchRef} className={`Search${!props.show ? " collapse" : ""}`} style={{ ...props.style }}>
            <div className="icon">
                <FontAwesomeIcon icon={faSearch} />
            </div>
            {props.show ?
                <div className="SearchContainer">
                    <input
                        type="text"
                        placeholder="Type to search..."
                        value={searchInput}
                        onChange={e => handleChange(e.target.value)} type="text" placeholder="Search Courses" autoFocus onBlur={_ => {
                            if (props.onBlur) {
                                props.onBlur()
                            }
                        }}
                    />
                    <div className="Results">
                        {data ?
                            data.map((course, i) => {
                                return (
                                    <A href={`/courses/${course.id}`} key={course.id}>
                                        <SearchResultItem
                                            key={i}
                                            course={course}
                                            CourseTitle={course.courseName}
                                            CourseDescription={course.aboutTheCourse}
                                        />
                                    </A>
                                )
                            })
                            : null}
                    </div>
                </div>
                : null}

        </div>
    )
}

Search.propTypes = {};

export default Search;