import React from 'react';

import './styles.scss';

const ProfileMini = props => {
	// Variables and Functions

	// Render
	return (
		<div className="ProfileMini" style={{ ...props.style }}>
			<div className="profile-image">
				<img alt="User Profile" src={props.profile.image} />
			</div>
			{!props.collapse ?
				<div className="content">
					<div className="name-title">{props.profile.name}</div>
					<div className="arrow">
						<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
							width="48" height="48"
							viewBox="0 0 172 172"
							style={{ fill: '#000000' }}><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#ffffff"><path d="M86,100.84217l-27.38383,-27.38383c-2.967,-2.967 -7.783,-2.967 -10.75,0v0c-2.967,2.967 -2.967,7.783 0,10.75l33.067,33.067c2.80217,2.80217 7.33867,2.80217 10.13367,0l33.067,-33.067c2.967,-2.967 2.967,-7.783 0,-10.75v0c-2.967,-2.967 -7.783,-2.967 -10.75,0z"></path></g></g></svg>
					</div>
				</div>
				: null}
		</div>
	)
}

ProfileMini.propTypes = {};

export default ProfileMini;