import React from 'react';
import { SkeletonText } from '..';
import './styles.scss';


const Subtitle = props => {
	// Variables and Functions

	// Render
	return (
		<div className="Subtitle" style={{ ...props.style }}>
			{props.loading ?
				<SkeletonText lineHeight={22} style={{ marginTop: 4, marginBottom: 12, maxWidth: "50%" }} />
				:
				<h2>
					{props.children}
				</h2>
			}
		</div>
	)
}

Subtitle.propTypes = {};

export default Subtitle;