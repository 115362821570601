import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonSpinner, IonCheckbox } from "@ionic/react";
import React, { useEffect, useMemo, useState } from 'react';
import { Button, PopupBase } from '..';
import * as firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/storage';
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import CrediTokenCard from '../CreditTokenCard';
import PricingOption from '../PricingOption';
import './styles.scss';


const Component = props => {
	// Variables and Functions
	const [working, setWorking] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [selectedPrice, setSelectedPrice] = useState(null);
	const [agreeChecked, setAgreeChecked] = useState(false);
	const [purchaseData, purchaseDataLoading] = useCollectionDataOnce(firebase.firestore().collection("fl_content").where('_fl_meta_.schema', '==', 'pricingOption'), { idField: "id" });
	const [purchaseOptions, setPurchaseOptions] = useState([]);
	const [optionSelectedID, setOptionSelectedID] = useState(false);
	// need to pass in the values to here so they are updated
	const [optionSelectedInfo, setOptionSelectedInfo] = useState({
		credit: 0,
		// do we pass in purchaseData.numberOfToken[i] ?? 
		value: 0
	});

	useEffect(() => {
		if (purchaseData) {
			let po = [];
			for (let i = 0; i < purchaseData.length; i++) {
				let pd = purchaseData[i];
				if (pd.enabled) {
					po.push(pd);
				}
				if (pd.length == 3) {
					break;
				}
			}
			setPurchaseOptions(po);
		}
	}, [purchaseDataLoading]);

	// const filteredData = purchaseData.filter(purchaseOption => purchaseOption.enabled === true).slice(0, 2).map((purchaseOption, index) => {
	// 	return (
	// 		<PricingOption
	// 			//i think the reasson why it wasnt updating value is bc when you call index, index is undefined
	// 			//in the map then youll be able to use index for onselect set option selected id 
	// 			purchaseOptions={purchaseData}
	// 			index={index}
	// 			selectedIndex={optionSelectedID}
	// 			onSelect={index => { setOptionSelectedID(index) }}
	// 			cardSelectInfo={info => setOptionSelectedInfo(info)}
	// 			CreditAmount={purchaseOption.numberOfTokens}
	// 			DollarAmount={purchaseOption.purchaseAmount}
	// 		/>
	// 	);
	// });

	// console.log(purchaseData);
	// console.log("OPTION SELECTED INFO: ", optionSelectedInfo);

	// const purchaseOptions = [{ id: purchaseData.id[0], credits: purchaseData.numberOfTokens[0], price: purchaseData.priceAmount[0] }, { id: purchaseData.id[1], credits: purchaseData.numberOfTokens[1], price: purchaseData.numberOfTokens[1] }, { id: purchaseData.id[2], credits: purchaseData.credits[2], price: purchaseData.credits[2] }];

	const doWork = _ => {
		setWorking(true);
		// On finish work
		if (props.onConfirm && !working) {
			props.onConfirm()
			setWorking(false);
		}
	}

	const dismiss = _ => {
		setWorking(true);
		if (props.onDismiss && !working) {
			props.onDismiss()
			setWorking(false);
		}
	}

	// Render
	return (
		<PopupBase>
			{working ?
				<div className="PurchaseCreditsPopup" style={{ ...props.style }}>
					<h1>{props.loadingTitle}</h1>
					<p>{props.loadingMessage}</p>
					<IonSpinner name="bubbles" style={{ transform: "scale(1.2)" }} />
				</div>
				:
				<div className="PurchaseCreditsPopup" style={{ ...props.style }}>
					<div className="PurchaseFlex">
						<div className="Heading">
							<h1>{props.title}</h1>
						</div>
						<div className="PricingOptionContainer">
							{purchaseDataLoading ?
								'loading'
								:
								// currently dynamic w flamelink 
								purchaseOptions
									.sort((a, b) => a.numberOfTokens > b.numberOfTokens ? 1 : -1)
									.slice(0, 3)
									.map((purchaseOption, index) => {
										return (
											<PricingOption
												//i think the reasson why it wasnt updating value is bc when you call index, index is undefined
												//in the map then youll be able to use index for onselect set option selected id 
												purchaseOptions={purchaseData}
												index={index}
												selectedIndex={optionSelectedID}
												onSelect={index => { setOptionSelectedID(index) }}
												cardSelectInfo={info => setOptionSelectedInfo(info)}
												CreditAmount={purchaseOption.numberOfTokens}
												DollarAmount={purchaseOption.purchaseAmount}
											/>
										)
									})
								// { filteredData }
							}
						</div>
						<div className="PlayerTerms">
							<div className="Lefty">
								<IonCheckbox value={agreeChecked} onIonChange={e => setAgreeChecked(!agreeChecked)} mode="md" />
							</div>
							<div className="Righty">
								<p>By checking this box you agree to our <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a></p>
							</div>
						</div>
						<div className="CardInformation">
							<CrediTokenCard
								dismiss={() => { dismiss(); window.location.reload(); }}
								credits={optionSelectedInfo.credit}
								priceAmount={optionSelectedInfo.value}
							/>
						</div>
						<div className="buttons-container">
							<Button onClick={_ => {
								dismiss()
							}} style={{ transition: 'unset !important', translate: 'scale(1) !important', backgroundColor: '#D23737', fontWeight: '600', fontSize: '18px', marginTop: 0, color: 'white' }}>{props.cancelTitle || "Cancel"}</Button>
						</div>
					</div>

				</div>
			}
		</PopupBase>
	)
}

Component.propTypes = {};

export default Component;