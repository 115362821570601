import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SkeletonText } from '..';

import './styles.scss';

const Image = props => {
	// Variables and Functions
	const [imageLoading, setImageLoading] = useState(true);

	// Render
	return (
		<div className={`Image${props.className ? ` ${props.className}` : ''}`} style={{ ...props.style }}>
			{imageLoading ?
				<SkeletonText lineHeight={200} style={{ borderRadius: 12 }} />
				: null}
			<img
				style={{ display: imageLoading ? "none" : "inline-block" }}
				alt={props.alt}
				src={props.src}
				onLoad={_ => {
					setImageLoading(false)
					if (props.onLoad) {
						props.onLoad(true)
					}
				}}
				onError={err => {
					setImageLoading(false)
					if (props.onLoad) {
						props.onLoad(false, err)
					}
				}}
			/>
		</div>
	)
}

Image.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired
};

export default Image;