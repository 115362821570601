import { A } from 'hookrouter';
import React, { useEffect, useState } from 'react';
import utils from 'synalgic-components/scripts/utils';
import { Button, Level, Score } from '..';
import { FinalPageImage } from '../../images';
import firebase from 'firebase';
import './styles.scss';
import { useProfile } from "../../hooks/useProfile";
import { useTokens } from "../../hooks/useTokens";
import { parseCookies, setCookie, destroyCookie } from 'nookies'

const FrameSizes = {
    small: "small",
    medium: "medium",
    large: "large"
}

const FramePaddings = {
    small: 24,
    medium: 36,
    large: 48
}

const Game = props => {
    // console.log(firebase.auth().currentUser.uid)
    // Variables and Functions
    const [sw, sh] = utils.useWindowSize();
    const [frameSize, setFrameSize] = useState(FrameSizes.medium)
    const [activeHole, setActiveHole] = useState(null);
    const [scoreInfo, setScoreInfo] = useState({
        score: 0,
        uid: firebase.auth().currentUser.uid,
        courseID: props.course.id,
        holes: [],
        ticketId: props.keyId, // Needs to be set as ticket id
    })
    const [getTokens] = useTokens();

    useEffect(_ => {
        let root = document.documentElement
        const ratio = sh / sw
        let width,
            height
        if (ratio <= 1.6) {
            width = (sh / 1.6)
            height = (sh)
        } else {
            width = (sw)
            height = (sw * 1.6)
        }
        root.style.setProperty('--frame-width', `${width}px`)
        root.style.setProperty('--frame-height', `${height}px`)
        const fSize = width > 500 ? FrameSizes.large : (width > 400 ? FrameSizes.medium : FrameSizes.small)
        setFrameSize(fSize)
        root.style.setProperty('--frame-padding', `${FramePaddings[fSize]}px`)
    }, [sw, sh])

    const _handleBallClicked = (holeInfo) => {
        if (holeInfo !== null) {
            let sInfo = { ...scoreInfo }
            sInfo.holes.push(holeInfo);
            sInfo.score = sInfo.score + holeInfo.strokes
            setScoreInfo(sInfo)
            incrementHolesCount();
        }
    }

    const incrementHolesCount = () => {
        firebase.firestore().collection('fl_content').doc(props.course.id).collection("AdditionalData").doc("holesPlayed").set({
            holesPlayed: firebase.firestore.FieldValue.increment(1)
        }, { merge: true })
    }

    const _handleNextHoleClicked = async _ => {
        if (props.holes.length > activeHole + 1) {
            setActiveHole(activeHole + 1)
        } else {
            setActiveHole(null)
            // HttpsCallable redeemToken() pass in { info: scoreInfo }
            const [tokens, error] = await getTokens()
            if (!error && tokens.length > 0) {
                let si = { ...scoreInfo };
                si.ticketId = tokens[0].id;
                var redeemToken = firebase.functions().httpsCallable('redeemToken')
                redeemToken({ info: si }).then(result => {
                    var msg = result.data
                    // console.log(msg)
                    // console.log('Called successfully :)')
                }).catch(error => {
                    // console.log('Error :( in sending the requests')
                });
            } else {
                // Handle no valid tokens at end of game
            }
        }
    }

    const _handlePlayClicked = _ => {
        const cookies = parseCookies()
        // console.log({ cookies })
        if (cookies.tutorial === "true") {
            setActiveHole(0);
        } else {
            setActiveHole(-1);
        }
    }

    // Render
    return (
        <div className="Game" style={{ ...props.style }}>
            <div className="layout">
                <div className="layout-content background-overlay"></div>
            </div>
            <div className="layout">
                <div className="layout-content frame">
                    {activeHole === null && scoreInfo.holes.length < 1 ?
                        <>
                            <img className="background" src={require('../../images/title-alt.png')} />
                            <Button className={`play-now ${frameSize}`}
                                onClick={_handlePlayClicked}>Play Now!</Button>
                        </>
                        : null}
                    {props.holes.map((hole, i) => {
                        return (
                            <Level key={hole.id}
                                className={activeHole === i ? "show" : ""}
                                active={activeHole === i}
                                hole={hole}
                                frameSize={frameSize}
                                onBallClicked={_handleBallClicked}
                                onNextHoleClicked={_handleNextHoleClicked}
                                score={scoreInfo.score} />
                        )
                    })}
                    {activeHole === null && scoreInfo.holes.length > 0 ?
                        <>
                            <img className="background" src={FinalPageImage} />
                            <Score frameSize={frameSize}>{scoreInfo.score || 0}</Score>
                            <A href={`/courses/${props.course.id}`}>
                                <Button className={`play-now ${frameSize}`}>Back to Tee to Green!</Button>
                            </A>
                        </>
                        : null}
                    {activeHole === -1 ?
                        <Level hole={null}
                            className={activeHole === -1 ? "show" : ""}
                            frameSize={frameSize}
                            onBallClicked={_handleBallClicked}
                            onNextHoleClicked={_handleNextHoleClicked}
                            score={scoreInfo.score} />
                        : null}
                </div>
            </div>
        </div>
    )
}

Game.propTypes = {};

export default Game;