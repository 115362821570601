import { IonSpinner } from "@ionic/react";
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React, { useState } from 'react';
import { Button, PopupBase } from '..';
import './styles.scss';

const SignUp = props => {
	// Variables and Functions
	const [data, setData] = useState({
		un: "",
		fn: "",
		ln: "",
		em: "",
		pw: "",
		zc: "",
		ag: "",
		hc: ""
	})

	const [working, setWorking] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null)

	const _handleInputValueChanged = (e, fieldKey) => {
		let newData = { ...data }
		newData[fieldKey] = e.target.value;
		setData(newData)
	}

	const _handleKeyDown = e => {
		if (e.key === 'Enter') {
			signUp();
		}
	}

	const signUp = _ => {
		let shouldContinue = true;
		let error = "Uh oh! Looks like you missed a required field"
		Object.keys(data).forEach(dataKey => {
			if (data[dataKey].length > 0 || dataKey === "ag" || dataKey === "hc") {
			} else {
				setErrorMessage(error);
				shouldContinue = false;
				return;
			}
		})
		if (shouldContinue) {
			setWorking(true);
			firebase.auth().createUserWithEmailAndPassword(data.em, data.pw).then(cred => {
				const uid = cred.user.uid
				firebase.firestore().collection("UserProfiles").doc(uid).set({
					username: data.un,
					firstName: data.fn,
					lastName: data.ln,
					email: data.em,
					zipCode: data.zc,
					age: data.ag,
					handicap: data.hc,
					tokenCount: 0
				}).then(doc => {
					setErrorMessage(null);
				}).catch(err => {
					setErrorMessage(err.message)
				}).finally(_ => {
					dismiss("Successfully created your account!");
					setWorking(false);
					window.location.reload()
				})
			}).catch(err => {
				setErrorMessage(err.message)
				setWorking(false);
			})
		}
	}

	const dismiss = message => {
		if (props.onDismiss && !working) {
			if (message) {
				props.onDismiss(message)
			} else {
				props.onDismiss()
			}
		}
	}

	// Render
	return (
		<PopupBase>
			{working ?
				<div className="SignUp popup-content" style={{ ...props.style, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
					<h1 mar>Creating Account</h1>
					<IonSpinner name="bubbles" style={{ transform: "scale(1.2)" }} />
				</div>
				:
				<div className="SignUp popup-content" style={{ ...props.style }}>
					<h1>Sign Up</h1>
					{errorMessage ?
						<div className="error-message">Error: {errorMessage}</div>
						: null}
					<h4>Required</h4>
					<input type="text" placeholder="Username" value={data.un} onChange={e => { _handleInputValueChanged(e, "un") }} onKeyDown={_handleKeyDown} />
					<input type="text" placeholder="First Name" value={data.fn} onChange={e => { _handleInputValueChanged(e, "fn") }} onKeyDown={_handleKeyDown} />
					<input type="text" placeholder="Last Name" value={data.ln} onChange={e => { _handleInputValueChanged(e, "ln") }} onKeyDown={_handleKeyDown} />
					<input type="text" placeholder="Email" value={data.em} onChange={e => { _handleInputValueChanged(e, "em") }} onKeyDown={_handleKeyDown} />
					<input type="password" placeholder="Password" value={data.pw} onChange={e => { _handleInputValueChanged(e, "pw") }} onKeyDown={_handleKeyDown} />
					<input type="number" placeholder="Zip Code" value={data.zc} onChange={e => { _handleInputValueChanged(e, "zc") }} onKeyDown={_handleKeyDown} />
					<h4>Optional</h4>
					<input type="number" placeholder="Age" value={data.ag} onChange={e => { _handleInputValueChanged(e, "ag") }} onKeyDown={_handleKeyDown} />
					<input type="number" placeholder="Handicap" value={data.hc} onChange={e => { _handleInputValueChanged(e, "hc") }} onKeyDown={_handleKeyDown} />

					<Button onClick={_ => {
						signUp();
					}}>Sign Up</Button>
					<Button onClick={_ => {
						dismiss()
					}} style={{ backgroundColor: "tomato" }}>Cancel</Button>
					<div className="no-account-container">
						<p>Already have an account? </p>
						<Button onClick={_ => {
							if (props.onShowSignIn) {
								props.onShowSignIn();
							}
						}}>Sign In</Button>
					</div>
				</div>
			}
		</PopupBase>
	)
}

SignUp.propTypes = {};

export default SignUp;