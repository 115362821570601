import React from 'react';
import { ProgressBar } from '..';
import './styles.scss';


const LoadingPopup = props => {
	// Variables and Functions

	// Render
	return (
		<>
			<div className="LoadingBackdrop">
				{/* {[0, 0, 0, 0, 0, 0, 0, 0].map((item, i) => {
					return (
						<div className="popup-spacer" key={i}></div>
					)
				})} */}
				<div className="LoadingModalContainer">
					<div className="LoadingPopup popup-content" style={{ ...props.style }}>
						<h1>{props.title || "Loading..."}</h1>
						{props.message ?
							props.message.split('\n').map((mess, i) => {
								return (
									<p key={i}>{mess}</p>
								)
							})
							:
							<p>Your message goes here...</p>
						}
						<ProgressBar progress={props.progress || 0} style={{ marginTop: 24 }} />
					</div>
				</div>
			</div>
		</>
	)
}

LoadingPopup.propTypes = {};

export default LoadingPopup;