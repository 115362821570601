import React from 'react';

import './styles.scss';

const HeaderButton = props => {
    return (
        <div className="HeaderButton" onClick={props.onClick} style={{ width: props.square ? 48 : "default", padding: props.square ? 0 : "0 24px", ...props.style }}>
            {props.children}
        </div>
    );
};

HeaderButton.propTypes = {};

export default HeaderButton;