import React, { useState, useMemo, useEffect } from 'react';
import './styles.scss';
import { Score } from '..';
import { BallImage } from '../../images';
import Button from '../Button';
import GolfSwing from '../../sounds/swing.mp3';
import LargeCheerNoise from '../../sounds/largecheer.mp3';
import Apploud from '../../sounds/apploud.mp3';
import GroanNoise from '../../sounds/crowdgroan.mp3';
import SampleHoleBackground from '../../images/sampleHole.png';
import TutorialOverlayImage from '../../images/tutorialOverlay.png';
import firebase from 'firebase';
import { setCookie } from 'nookies'

const Level = props => {
    // Variables and Functions
    const [strokes, setStrokes] = useState(0)
    const [selectedBall, setSelectedBall] = useState(null)
    const [disableBall, setDisabledBall] = useState(false)
    const [adImage, setAdImage] = useState(null);


    let swing = new Audio(GolfSwing);
    let apploud = new Audio(Apploud);
    let largecheer = new Audio(LargeCheerNoise);
    let groan = new Audio(GroanNoise);

    useEffect(() => {
        if (props.hole && props.hole.adImage && props.hole.adImage[0] && adImage === null) {
            const imageSize = 3;
            props.hole.adImage[0].get().then((snap) => {
                let image = snap.data()
                const sizedFolder = image.sizes[imageSize].path;
                firebase.storage().ref(`flamelink/media/sized/${sizedFolder}/${image.file}`).getDownloadURL().then((downloadURL) => {
                    setAdImage(downloadURL)
                })
            })
        }
    }, [props.hole])

    useEffect(() => {
        if (props.active && props.hole.holeAudioURL) {
            let startingAudio = new Audio(props.hole.holeAudioURL)
            startingAudio.play()
        }
    }, [props.active])
    //audio delcaration

    const balls = [
        { name: "one" },
        { name: "two" },
        { name: "three" },
        { name: "four" },
        { name: "five" },
    ]

    const strokeSizes = {
        small: 24,
        medium: 36,
        large: 48,
    }

    const generateStrokes = _ => {
        if (props.holes !== null) {
            const min = props.hole.minimumStrokes,
                max = props.hole.maximumStrokes
            const total = (max - min) + 1
            let rand = Math.floor(Math.random() * total)
            if (rand === total) {
                rand--
            }
            return min + rand
        } else {
            return 2
        }
    }

    const getBoxVisibility = offset => {
        if (offset === null) {
            return strokes === 1 ? " show" : ""
        }
        return strokes === (props.hole ? props.hole.par + offset : 3 + offset) ? " show" : ""
    }

    useEffect(() => {
        const par = props.hole ? props.hole.par : 3;
        if (strokes === par - 2) {
            largecheer.play()
        };
        if (strokes === par - 1) {
            largecheer.play()
        };
        if (strokes === par - 0) {
            apploud.play()
        };
        if (strokes === par + 1) {
            groan.play()
        };
        if (strokes === par + 2) {
            groan.play()
        };

    }, [strokes, props.hole]);

    const _handleBallClicked = ball => {
        if (props.hole !== null) {
            // if (selectedBall === null) {
            let numOfStrokes = props.hole.majorHoleInOne ? 1 : generateStrokes()
            // console.log(props.hole)
            if (!props.hole.majorHoleInOne && props.hole.minorHoleInOne) {
                const random = generateStrokes()
                if (numOfStrokes === random) {
                    numOfStrokes = 1
                }
            }
            setDisabledBall(true)
            setStrokes(numOfStrokes)
            setSelectedBall(ball.name)
            let holeInfo = {
                holeID: props.hole.id,
                holeName: props.hole.name,
                strokes: numOfStrokes,
                par: props.hole.par
            }
            if (props.onBallClicked) {
                props.onBallClicked(holeInfo);
            }
            if (ball) {
                swing.play()
            }
            // }
        } else {
            const numOfStrokes = 2; // Default for Sample
            setDisabledBall(true);
            setStrokes(numOfStrokes);
            setSelectedBall(ball.name);
            if (props.onBallClicked) {
                props.onBallClicked(null);
            }
            if (ball) {
                swing.play()
            }
        }
    }

    const _handleNextHoleClicked = _ => {
        if (props.hole === null) {
            setCookie(null, 'tutorial', true)
        }
        if (props.onNextHoleClicked) {
            props.onNextHoleClicked()
        }
    }

    const addClassNames = useMemo(() => _ => {
        let addClasses = ""
        addClasses += props.className ? ` ${props.className}` : ""
        return addClasses
    }, [props.className])

    // Render
    return (
        <div className={`Level${addClassNames()}`} style={{ ...props.style }}>
            <img className="background" src={props.hole ? props.hole.holeImageURL : SampleHoleBackground} alt={'Hole Background'} />

            {props.hole !== null ?
                <Score frameSize={props.frameSize}>{props.score || 0}</Score>
                : null}

            {balls.map((ball, i) => {
                return (
                    <div key={ball.name} className={`ball par${props.hole ? props.hole.par : 3} ${ball.name}${selectedBall === ball.name ? ' selected' : ''}`} onClick={_ => { disableBall ? console.log('balls disbaled') : _handleBallClicked(ball) }}>
                        <img key={i} src={BallImage} alt={`Ball selection ${i}`} />
                        <div className="hole-strokes" style={{ fontSize: strokeSizes[props.frameSize] }}>{strokes}</div>
                    </div>
                )
            })}

            {props.hole === null ?
                <img className="background" src={TutorialOverlayImage} alt={'Tutorial Hole Overlay'} />
                : null}

            {adImage ?
                <div className={`adSpace`}>
                    <a href={props.hole.adLink} target="_blank" rel="noopener noreferrer">
                        <img src={adImage} alt="Ad Space" />
                    </a>
                </div>
                : null}

            <div className={`overlay holeInOne${getBoxVisibility(null)}`}>
                <div className="boxey">
                    <h1 style={{ backgroundColor: '#321214' }}>Hole in one!</h1>
                    <div className="innerBox">
                        <p>Hole in one!</p>
                    </div>

                </div>
            </div>
            <div className={`overlay eagle${getBoxVisibility(-2)}`}>
                <div className="boxey">
                    <h1 style={{ backgroundColor: '#2D2815' }}>Eagle!</h1>
                    <div className="innerBox">
                        <p>Awesome!</p>
                    </div>

                </div>
            </div>
            <div className={`overlay birdie${getBoxVisibility(-1)}`}>
                <div className="boxey">
                    <h1 style={{ backgroundColor: '#321214' }}>Birdie</h1>
                    <div className="innerBox">
                        <p>Great Choice!</p>
                    </div>

                </div>
            </div>
            <div className={`overlay par${getBoxVisibility(0)}`}>
                <div className="boxey">
                    <h1 style={{ backgroundColor: '#123819' }}>Par</h1>
                    <div className="innerBox">
                        <p>Staying Even</p>
                    </div>

                </div>
            </div>
            <div className={`overlay bogey${getBoxVisibility(1)}`}>
                <div className="boxey">
                    <h1 style={{ backgroundColor: '#121731' }}>Bogey or Better</h1>
                    <div className="innerBox">
                        <p>You've got this!</p>
                    </div>

                </div>
            </div>
            <div className={`overlay doubleBogey${getBoxVisibility(2)}`}>
                <div className="boxey">
                    <h1 style={{ backgroundColor: '#32122E' }}>Double Bogey</h1>
                    <div className="innerBox">
                        <p>Choose Wisely!</p>
                    </div>

                </div>
            </div>

            {selectedBall ?
                <Button className={`next-hole ${props.frameSize}`}
                    onClick={_handleNextHoleClicked}>Next Hole</Button>
                : null}
        </div>
    )
}

Level.propTypes = {};

export default Level;