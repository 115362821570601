import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import utils from '../../scripts/Utilities';

import './styles.scss';

const Button = props => {
	// Variables and Functions
	const flexDirection = (_ => {
		let flexDirection
		switch (props.iconPosition) {
			case utils.Positions.Left:
				flexDirection = "row";
				break;
			case utils.Positions.Right:
				flexDirection = "row-reverse";
				break;
			case utils.Positions.Top:
				flexDirection = "column";
				break;
			case utils.Positions.Bottom:
			default:
				flexDirection = "column-reverse";
				break;
		}
		return flexDirection
	})()

	const addClassNames = useMemo(() => _ => {
		let addClasses = ""
		addClasses += props.className ? ` ${props.className}` : ""
		addClasses += props.highlight ? ` highlight` : ""
		addClasses += props.darkMode ? ` darkMode` : ""
		return addClasses
	}, [props.className, props.highlight, props.darkMode])

	// Render
	return (
		<div className={`Button${addClassNames()}`}
			style={{ ...props.style, flexDirection: flexDirection }}
			onClick={e => {
				if (props.onClick) {
					props.onClick(e);
				}
			}}>
			{props.icon ?
				<div>{props.icon}</div>
				: null}
			{props.icon ?
				<div className="divider"></div>
				: null}
			<div style={{ color: props.style ? props.style.color || "white" : "white" }}>{props.children}</div>
		</div>
	)
}

Button.defaultProps = {
	iconPosition: utils.Positions.Left
}

Button.propTypes = {
	iconPosition: PropTypes.oneOf(Object.values(utils.Positions))
};

export default Button;