import React, { useEffect, useMemo, useState } from 'react';
import './styles.scss';


const ProgressBar = props => {
	// Variables and Functions
	const [progress, setProgress] = useState(0)

	useEffect(() => {
		if (props.progress) {
			setProgress(props.progress)
		} else {
			setProgress(0)
		}
	}, [props.progress])

	const getXOffset = value => {
		if (value <= 0) {
			return -100
		}
		if (value >= 100) {
			return 0
		}
		return -(100 - value)
	}
	const xOffset = useMemo(() => getXOffset(progress), [progress])

	// Render
	return (
		<div className="ProgressBar" style={{ ...props.style }}>
			<div className="track">
			</div>
			<div className="progress" style={{ transform: `translateX(${xOffset}%)` }}>
			</div>
		</div>
	)
}

ProgressBar.propTypes = {};

export default ProgressBar;