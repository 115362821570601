import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import * as firebase from 'firebase/app';

const Settings = props => {
    //create one data state to pass to firebase
    const [data, setData] = useState({ 'firstName': '', 'lastName': '', 'email': '', 'zip': '', });
    //onsbumit to fire our firestore update
    const onSubmit = () => {
        const db = firebase.firestore();
        db.collection('UserProfiles').doc(props.profile.id).update({
            firstName: `${data.firstName}`,
            lastName: `${data.lastName}`,
            email: `${data.email}`,
            zipCode: `${data.zip}`
        }).then(() => {
            // console.log('Profile Successfully Edited!');
            props.dismissOnClick()
            window.location.reload(false);
        }).catch((error) => {
            alert('Error updating the document:', error);
        })
    }
    const updateData = (field, e) => {
        const value = e.target.value;
        setData(data => ({ ...data, [field]: value }));
    };
    //use effect will et our starting data
    useEffect(() => {
        setData({
            firstName: `${props.profile.firstName}`,
            lastName: `${props.profile.lastName}`,
            email: `${props.profile.email}`,
            zip: `${props.profile.zipCode}`,
        });
    }, []);
    return (
        <div className={`${styles.Settings} ${props.classPass}`}>
            <div className={styles.ModalHeader}>
                <h2>Account Settings</h2>
                <div className={styles.RefillPrompt}>
                    <p>Looking to refill your account? <span className={styles.action} onClick={props.openCreditsPopup}>Refill Here</span></p>
                </div>
            </div>
            <div className={styles.SettingsContent}>
                <div className={styles.Inputs}>
                    <div className={styles.InputContainer}>
                        <h2>First Name</h2>
                        <input value={data.firstName} onChange={e => updateData('firstName', e)} type="text"></input>
                    </div>
                    <div className={styles.InputContainer}>
                        <h2>Last Name</h2>
                        <input value={data.lastName} onChange={e => updateData('lastName', e)} type="text"></input>
                    </div>
                    <div className={styles.InputContainer}>
                        <h2>Email</h2>
                        <input value={data.email} onChange={e => updateData('email', e)} type="text"></input>
                    </div>
                    <div className={styles.InputContainer}>
                        <h2>Zip</h2>
                        <input value={data.zip} onChange={e => updateData('zip', e)} type="text"></input>
                    </div>
                </div>
            </div>
            <div className={styles.Buttons}>
                <div className={styles.button} onClick={props.saveOnClick}>
                    <h2 onClick={_ => onSubmit()}>Save Changes</h2>
                </div>
                <div className={styles.buttonClose} onClick={props.dismissOnClick}>
                    <h2>Back</h2>
                </div>
                {/* <div className={styles.buttonClose} onClick={props.openCreditsPopup}>
                    <h2>Purchase Credits</h2>
                </div> */}
            </div>
        </div>
    );
};

Settings.propTypes = {};

export default Settings;