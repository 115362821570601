import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { useEffect, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';

export function useProfile() {

    const [user, loadingUser, errorUser] = useAuthState(firebase.auth())

    const [profile, setProfile] = useState(null);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [errorProfile, setErrorProfile] = useState(null);

    useEffect(() => {
        setErrorProfile(null)
        if (user) {
            setLoadingProfile(true)
            getProfile(user)
        } else {
            setProfile(null)
            setErrorProfile(null)
        }
    }, [user])

    const getProfile = (userData) => {
        // console.log(userData)
        firebase.firestore().collection('UserProfiles').doc(userData.uid)
            .get()
            .then(doc => {
                setLoadingProfile(false)
                let prof = doc.data()
                prof.id = doc.id
                setProfile(prof)
            })
            .catch(err => {
                setLoadingProfile(false)
                setErrorProfile(err)
                console.log("Error getting user profile: ", err);
            })
    }

    const updateProfile = data => {
        // TODO: Update profile info
    }

    return {
        userInfo: [user, loadingUser, errorUser],
        profileInfo: [profile, loadingProfile, errorProfile],
        updateProfile
    }

}